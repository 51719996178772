$(document).ready(function () {
  $("pre code").each(function (i, block) {
    hljs.highlightBlock(block);
  });

  $(".sticky").Stickyfill();

  $(".nav-wrapper a").click(function () {
    $("html, body").animate(
      {
        scrollTop: $($.attr(this, "href")).offset().top,
      },
      400
    );
  });

  // http://jsfiddle.net/x3V6Y/
  $(function () {
    var sections = {},
      _height = $(window).height(),
      i = 0;

    // Grab positions of our sections
    $(".grouped-content a.anchor").each(function () {
      sections[this.name] = $(this).offset().top + 500;
    });

    $(document).scroll(function () {
      var $this = $(this),
        pos = $this.scrollTop();

      for (i in sections) {
        if (sections[i] > pos && sections[i] < pos + _height) {
          $("a").removeClass("selected");
          $("#nav_" + i).addClass("selected");
        }
      }
    });
  });
});
