// First, import and set up jQuery globally
import "../../assets/javascripts/jquery.js";

// Verify jQuery is loaded
console.log("jQuery version:", jQuery.fn.jquery);

import Rails from "@rails/ujs";
Rails.start();

// jQuery UI
import "jquery-ui-dist/jquery-ui";

import "@kanety/jquery-nested-form";

// Import Popper first
import * as Popper from "@popperjs/core";
window.Popper = Popper;

// Import all Bootstrap JS components
import { Collapse, Dropdown, Modal, Tab, Alert, Button } from "bootstrap";
import {
  getElement,
  getUID,
  getSelectorFromElement,
} from "bootstrap/js/dist/base-component";

// Make Bootstrap components available globally
window.bootstrap = {
  Collapse,
  Dropdown,
  Modal,
  Tab,
  Alert,
  Button,
  utils: {
    getElement,
    getUID,
    getSelectorFromElement,
  },
};

import "waypoints/lib/jquery.waypoints";

// Vendor files - these need to be moved to node_modules or your assets
import "../../assets/javascripts/vendor/highlight.min";
import "../../assets/javascripts/vendor/stickyfill";
import "../../assets/javascripts/vendor/autocomplete-rails";

// // For autocomplete-rails, you might need to use the npm package
// import "autocomplete-rails";
// // Or if using the asset version:

import "../../assets/javascripts/application.js";
